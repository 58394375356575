
@import '../utils/variables';

.aggregation-modal {

  &__wrapper {
    max-height: 70vh;
    overflow: auto;

    .list-group-item {
      border: 0;

      &:nth-child(even) {
        background: $gray-100;
      }
    }
  }
}
