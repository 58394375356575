@import 'node_modules/@icij/murmur/lib/styles/functions';
@import 'node_modules/@icij/murmur/lib/styles/mixins';

@mixin record-card($bg, $emphasis, $header-bg: $bg, $header-color: text-contrast($header-bg)) {
  border-top-color: $emphasis;
  border-top-width: 3px;
  overflow: hidden;

  &--more {
    padding: $spacer * 4 0;
    border:1px dotted mix($header-bg, $emphasis);
    color: $emphasis;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: lighter;
    text-align: center;
    display: block;
    cursor: pointer;
  }

  .card-header {
    background: $header-bg;
    color: $header-color;
    border-color: mix($header-color, $header-bg, 20);

    a {
      color: inherit;
    }
  }
}

@mixin colorize-heading($bg, $emphasis) {
  &__heading {
    background: $bg;
    overflow: hidden;

    a {
      color: mix($emphasis, $body-color, 60)
    }

    &__wtf {
      background: $emphasis;
      padding: $spacer $spacer * 2;
      color: text-contrast($emphasis);
      box-shadow: 0 -30px 0 0 $emphasis, 0 -60px 0 0 $emphasis;
      border-bottom: 4px solid white;

      @include media-breakpoint-down(md) {
        box-shadow: none;
      }

      a {
        color: mix($emphasis, text-contrast($emphasis));
      }
    }
  }
}
