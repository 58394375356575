.main-nav {
  border-bottom: 1px solid $card-border-color;
  white-space: nowrap;

  &, & > * {
    width: 100%;    
  }

  @include media-breakpoint-down(md) {
    font-size: 0.9rem;
  }

  ul {
    overflow: auto;
    min-width: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  &__item {
    padding-left: $spacer;
    padding-right: $spacer;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    &:not(:last-child) {
      position: relative;

      &:after {
        position: absolute;
        left: 100%;
        top: 50%;
        content: "";
        background: black;
        height: 1em;
        width: 1px;
        transform: translateY(-50%);
      }
    }
  }

  .search-form {
    margin-left: $spacer * .5;
    max-width: 330px;

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

}
