
@import '../utils/variables.scss';

.advanced-search-form {
  $advanced-search-form-bg: $dark;

  border-radius: $input-border-radius;
  background: $advanced-search-form-bg;

  .search-form--lg .search-form__group {
    width: 100%;
    max-width: 100%;

    .form-control, .btn, .autosuggest__results {
      background: darken($advanced-search-form-bg, 7%);
      border-color: darken($advanced-search-form-bg, 10%);
      color: white;

      .autosuggest__results_item:not(:hover) {
        color: inherit;
      }

      &::placeholder {
        color: rgba(white, .4);
      }
    }
  }

  & &__filters-btn, & &__embed-btn, & &__embed-btn {
    box-shadow: inset 0 0 0 1px white;

    &:focus, &:hover {
      box-shadow: inset 0 0 0 1px white;
      text-decoration: none;
    }
  }

  & &__layout-btn .btn {
    font-size: 0.8em;
  }

  &__filters-btn {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 100%;
      margin-right: calc(#{$input-btn-focus-width} + #{$input-btn-border-width});
      width: $spacer;
      z-index: 10;
      @include gradient-x(rgba($advanced-search-form-bg, 0), $advanced-search-form-bg);
    }

    &.active {
      border-color: darken($advanced-search-form-bg, 10%);
      color: lighten($component-active-bg, 15) !important;
    }
  }

  &__tabs {
    padding:0 $spacer;
    overflow: auto;
    white-space: nowrap;
    max-width: 100%;

    &__item {
      margin-right: 0;

      a {
        display: inline-block;
        padding: ($spacer * .5) $spacer;
        color: rgba(white, .5);

        &:hover {
          color: lighten($component-active-bg, 20%);
        }

        &.active {
          color: white;

          .badge {
            background: white;
          }
        }

        .badge {
          font-size: .6em;
          position: relative;
          top: -.6em;
          background: rgba(white, .5);
        }
      }
    }
  }

  &__filters {
    background: darken($advanced-search-form-bg, 10%);
    color: white;
    border-radius: 0 0 $input-border-radius $input-border-radius;
  }
}
