.callout-button {
  $link-hover-color: rgba(text-contrast($callout-button-color), .4);
  background: $callout-button-bg;
  color: $callout-button-color;
  border-color: $callout-button-color;

  &:hover {
    color: $callout-button-color;
    text-decoration: none;

    .btn {
      color: text-contrast($callout-button-emphasis);
      background: $callout-button-emphasis;
      border-color: $callout-button-emphasis;
    }
  }

  &__icon {
    color: $callout-button-emphasis;
    text-shadow: 1px 1px 0 $link-hover-color, 2px 2px 0 $link-hover-color;
  }

  &__cta.btn {
    white-space:normal;
    text-transform: uppercase;
    color: inherit;
    border-color: inherit;
    border-width: 3px;
    font-weight: bolder;
    box-shadow: 1px 1px 0 $link-hover-color, 2px 2px 0 $link-hover-color;

    border-color: text-contrast($callout-button-bg);
    background: text-contrast($callout-button-bg);
    color: text-contrast(text-contrast($callout-button-bg));
  }
}
