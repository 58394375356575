@import url('https://use.fontawesome.com/releases/v5.3.1/css/all.css');
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,700,800|Lato:300,400,400italic,700');

@import './utils/variables.scss';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/@icij/murmur/dist/lib/murmur';
@import 'node_modules/bootstrap-vue/dist/bootstrap-vue-icons.min';
@import 'node_modules/bootstrap-vue/dist/bootstrap-vue.min';


// Static pages
@import './pages/home.scss';
@import './pages/index.scss';
@import './pages/callout.scss';
@import './pages/callout-embed.scss';
// Search
@import './search/index.scss';
@import './search/embed.scss';
// Devices' pages
@import './devices/card.scss';
@import './devices/show.scss';
// Events' pages
@import './events/card.scss';
@import './events/show.scss';
@import './events/countries.scss';
// Manufacturers' pages
@import './manufacturers/card.scss';
@import './manufacturers/show.scss';
// Shared partials
@import './shared/callout-button.scss';
@import './shared/main-nav.scss';
@import './shared/sidebar.scss';
@import './shared/stories.scss';

[v-cloak] {
  display: none !important;
}

.header-gap {
  margin-top: 2rem + 2 * $spacer;
}

.generic-footer {
  overflow-x: hidden;
}

.popover {
	width: 100%;
}

.turbolinks-progress-bar {
  background-color: $primary;
}

.rounded-pill {
  padding-right: $badge-pill-padding-x;
  padding-left: $badge-pill-padding-x;
  border-radius: $badge-pill-border-radius;
  min-width: 1em;
  display: inline-block;
}

.ticked-title {
  padding-top: $spacer;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: $spacer * 6;
    height: $spacer * 0.5;
    background: black;
    transform: translateY(-50%);
  }

  &:after {
    content:"";
    position: absolute;
    width: 100vw;
    top: 0;
    right: 100%;
    border-top: 1px dashed #000;
    height: 1px;
    margin-right: -$spacer;
  }
}

.list-group-truncate {
  display: flex;
  flex-direction: row;
  align-items: start;
  max-width: 100%;

  &-item {
    font-style: normal;
    overflow: hidden;
    max-width: 100%;
    flex-grow: 1;
    min-width: 0;
    text-align: right;
  }
}
