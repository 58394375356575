.device {
  @include colorize-heading($device-bg, $device-emphasis);

  .card-columns {
    column-count: 2;

    @include media-breakpoint-down(sm) {
      column-count: 1
    }
  }
}
