.events__countries {
  background: $home-overview-bg;
  padding: 0;
  margin: 0;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px black;


  .app--no-embeddable-footer & {
    box-shadow: 0;
  }

  .embedded & {
    overflow: hidden;
  }

  .not-embedded & {
    height: 100vh;
    overflow: hidden;
  }

  &__map {
    background: transparent;
    z-index: $zindex-sticky - 10;
    overflow: visible;
    flex: 1;

    &.events-map--not-embedded-with-pym {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &.events-map--embedded-with-pym {
      height: auto;

      .events-map__wrapper {
        min-height: 110px;
      }
    }

  }
}
