
@import '../utils/variables';

.events-map {
  width: 100%;
  height: 100%;
  background: $home-overview-bg;

  &__wrapper {
    min-height: 0;
    width: 100%;
    position: relative;

    & > div {

      .datamap {
        overflow: visible !important;

        .datamaps-subunits {
          filter: drop-shadow(0 -1rem 2rem darken($home-overview-bg, 10));
        }
      }

      .datamaps-hoverover .hoverinfo {
        box-shadow: $popover-box-shadow;
        border: none;
      }
    }
  }


  &__instructions {
    display: flex;
    flex-direction: row;
    align-items: center;

    position: absolute;
    bottom: 0;
    left: 0;

    z-index: 2;
    color: #fff;
    background: #000;
    width: 100%;
    box-shadow: $grid-gutter-width 0 0 black, -$grid-gutter-width 0 0 black;

    &__body {
      flex-grow: 1;
      min-width: 0;
    }

    &__embed-btn.btn {
      background: #fff;
      width: 2.2rem;
      height: 2.2rem;
      line-height: 2.2rem;
      border-radius: 50%;
      text-align: center;
    }
  }
}
