$tradewind: #68beb0 !default;
$cuttysark: #50676b !default;
$blumine: #195172 !default;
$mercury: #e5e5e5 !default;
$copper: #a57a36 !default;
$bostonblue: #3b98ab !default;
$punch:#A83324 !default;

$dark: #333;
$primary: $bostonblue;
$link-color: $primary !default;
$link-hover-color: darken($primary, 10%) !default;

@import 'node_modules/@icij/murmur/lib/styles/lib';
@import 'node_modules/@icij/murmur/lib/styles/functions';
@import 'node_modules/@icij/murmur/lib/styles/mixins';
@import './mixins';

$font-family-sans-serif: "Fira Sans", $font-family-sans-serif;
$font-family-serif: "Merriweather", Merriweather, $font-family-serif;

$headings-font-family: $font-family-sans-serif;
$font-family-base: "Lato", $font-family-sans-serif;

$event-bg: #fde7d6;
$event-emphasis: #ab4e3c;

$device-bg: #d7eef2;
$device-emphasis: #195172;

$manufacturer-bg: #ded2c2;
$manufacturer-emphasis: #806147;

$home-overview-bg: #b0d2e0;
$stories-bg: #ffe0c9;

$callout-button-bg: $dark;
$callout-button-color: #fff;
$callout-button-emphasis: #F8E18A;
