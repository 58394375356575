@use "sass:math";

.stories {
  margin:$spacer * 2 0 0;
  position: relative;
  padding: $spacer * 2 0;

  @include media-breakpoint-up(md) {
    margin-bottom: $spacer * 2;
  }

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background: $stories-bg;
    z-index: -1;

    @include media-breakpoint-down(md) {
      left: 30%;
    }

    @include media-breakpoint-down(sm) {
      left: 0;
    }
  }

  &__item {
    margin: $spacer 0;
    max-width: 350px;
    width: 100%;
    position: relative;
    padding-bottom: $spacer;
    color: $body-color;

    @include media-breakpoint-down(lg) {
      max-width: 30%;
    }

    @include media-breakpoint-down(md) {
      max-width: 50%;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    &:hover {
      color: inherit;
      text-decoration: none;
    }

    &__cover {
      overflow: hidden;
      width: 100%;
      padding-top: math.div(100% * 2, 3);
      position: relative;

      img {
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: 300ms transform;
      }
    }

    &__heading {
      padding-top: $spacer;

      &__title {
        font-size: 1rem;
        margin: 0;
      }

      &__authors {
        color: $text-muted;
        font-style: oblique;
        font-size: .9rem;
      }
    }
  }
}
