.page {

  &__body {
    font-size: 1rem;

    ul:not(.list-unstyled) li {
      list-style: square;
    }

    h5 {
      font-weight: 400;
    }
  }

  &__header {
    padding: 0;
    padding-top: calc(4vh + 4vw);
    margin-bottom: $spacer * 2;
    position: relative;

    .page--no-cover & {
      border-bottom: dashed 1px #000;
    }

    .page--with-cover & {
      color: white;
      overflow: hidden;
      padding-top: calc(7vh + 7vw);
      padding-bottom: 2vh;


      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        @include gradient-directional(rgba(#000, 0), rgba(#000, 0.5), 360deg * -1 * 0.25);
      }
    }

    &__title {
      font-size: 4rem;
      font-weight: 800;
      font-style: oblique;

      @include media-breakpoint-down(md) {
        font-size: calc(1.5rem + 1vh);
      }
    }

    &__lead {
      font-size: 2rem;
      font-family: $font-family-serif;
      padding-bottom: $spacer;
    }

    &__authors {
      font-size: 1.2rem;
      font-style: oblique;
    }

    &__cover {
      position: absolute;
      top: 0;
      left:0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background: center no-repeat;
      background-size: cover;
    }

     &__divider {
        @extend .container;
        position: relative;
        height: $spacer * 2;

        &:after {
         content: "";
         position: absolute;
         top: 100%;
         left: 0;
         width: $spacer * 6;
         height: $spacer * 0.5;
         transform: translate(0, -50%);
         background: #000;
       }
     }
  }
}
