.event {

  @include colorize-heading($event-bg, $event-emphasis);

  &__manufacturers {
    column-count: 2;
    column-gap: $spacer;

    @include media-breakpoint-down(sm) {
      column-count: 1;
    }
  }
}
