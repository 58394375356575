.search {

  &__results {

    @include media-breakpoint-down(md) {
      white-space: nowrap;
    }

    thead tr th {
      border-top: 0;
      text-transform: uppercase;
      font-size: 0.9em;
      color: $text-muted;
      font-weight: bold;

      a {
        color: $body-color;

        &.active {
          font-weight: bolder;

          &:after {
            font-family: "Font Awesome 5 Free";
            margin-left: $spacer * 0.5;
          }

          &.asc:after { content: "\f063"; }
          &.desc:after { content: "\f062"; }
        }
      }
    }

    tbody tr {

      td {
        border-top-color: transparent;
        border-width: 0;
      }
    }

    &__main-column {
      max-width: 30vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .card-columns {
    @include media-breakpoint-down(lg) {
      column-count: 2;
    }

    @include media-breakpoint-down(sm) {
      column-count: 1;
    }
  }

  &__pagination {
    // View generated by kaminary
    .pagination {
      @extend .justify-content-end;
    }
  }
}
