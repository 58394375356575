.search__embed {
  border: 1px solid black;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: 4rem;

  .app--no-embeddable-footer & {
    padding-bottom: 0;
    border: 0;
  }

  &__form.search-form {
    .search-form__group {
      max-width: 100%;
      width: 100%;
    }
  }
}
