.callout__embed {
  border: 1px solid black;
  padding-top: $spacer;
  padding-bottom: 4rem;

  .app--no-embeddable-footer & {
    padding-bottom: 0;
    border: 0;
  }
}
