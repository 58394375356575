@use "sass:math";

.home {
  min-height: 80vh;
  // Prevent a bug with the aggregation-card in mobile format
  overflow-x: hidden;
  max-width: 100%;

  &__jumbotron {
    margin-bottom: 10vh;

    &__lead-image {
      align-self:stretch;
      background: no-repeat center center white;
      background-size: contain;

      @include media-breakpoint-down(lg) {
        flex: 0 0 50vw;
        width: 50vw;
        height: 50vw;
        img { height: 100%; }
      }

      @include media-breakpoint-down(md) {
        flex: 0 0 100%;
        width: 100%;
        height: math.div(100vw * 9, 16);
        margin: $spacer 0;
      }
    }

    &__heading {
      max-width: 660px;
      padding: $spacer;
      margin: 10vh auto 0;

      @include media-breakpoint-down(md) {
        margin: 0 auto 0;
      }

      &__title {
        font-size: 3rem;
        font-weight: 800;

        @include media-breakpoint-down(lg) {
          font-size: 2.5rem;
        }

        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
      }

      &__lead {
        font-size: 1.3rem;
        font-family: $font-family-serif;
      }

      &__byline {
        font-style: italic;
        font-size: 1rem;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }
    }

    &__form {
      max-width: 660px;
      margin: 2vh auto 5vh;
      padding: $spacer;
    }
  }

  &__overview {
    position: relative;
    background: $home-overview-bg;
    overflow: hidden;
    flex-grow: 2;
    min-width: 0;

    @media screen and (max-width: 1130px) {
      width: 100%;
      flex: 0 0 100%;

      .events-map {
        min-height: 56vw;
      }
    }
  }

  &__classifications {
    max-width: 280px;

    @media screen and (max-width: 1130px) {
      margin-left: 0;
      max-width: calc(100% - #{-2 * $spacer});
    }

    .card-columns {
      column-count: 1;
      column-gap: $spacer * 2;
      margin: 0 $spacer;
      margin-top: $spacer * -1;

      .card {
        margin: $spacer 0 0;
      }

      @media screen and (max-width: 1130px) {
        column-count: 3;
        column-gap: $spacer;
        margin: $spacer * 0.5;
      }

      @include media-breakpoint-down(sm) {
        column-count: 2;
      }

      @include media-breakpoint-down(xs) {
        column-count: 1;
      }
    }
  }

  &__callout {

    @include media-breakpoint-up(xl) {
      max-width: 330px;
      flex: 0 0 330px;
    }

    &__wrapper {
      background: $callout-button-bg;
      height: 100%;
      color: white;
    }

  }

  &__about {
    &__description {
      font-size: 1.1em;
    }
  }
}
