
@import '../utils/variables.scss';
@import 'node_modules/bootstrap/scss/bootstrap';

.screendoor-form {

  textarea,
  input[type=text],
  input[type=email] {
    @extend .form-control;
  }

  select {
    @extend .custom-select;
  }

  .fr_description {
    @extend .text-muted;
  }


  .fr_button {
    @extend .btn;
    @extend .btn-primary;

    &[data-fr-next-page] {
      font-weight: bolder;
      text-transform: uppercase;
    }
  }

  .fr_option.control {
    @extend .form-check;

    input[type="radio"], input[type="checkbox"] {
      @extend .form-check-input;
    }
  }
}
