
@import '../utils/variables';

$custom-select-color: #fff;
$custom-select-indicator-color: $custom-select-color;
$custom-select-indicator: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"), "#", "%23");

.search-form-filters {

  &__clear {
    color: lighten($copper, 20);
    line-height: 1;
  }

  .form-group {

    .custom-select {
      background-color: rgba(black, .5);
      background-image: $custom-select-indicator;
      border-color: rgba(black, .8);
      color: $custom-select-color;
    }

    &.active {
      color: lighten($copper, 20);
      font-weight: bold;

      .custom-select {
        box-shadow: 0 0 0 3px lighten($copper, 20);
      }
    }
  }
}
