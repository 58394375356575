.mw-sidebar {
  max-width: calc(100% - 350px);
  flex: 0 0 calc(100% - 350px);

  @include media-breakpoint-down(md) {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.sidebar {
  max-width: 350px;
  flex: 0 0 350px;
  font-size: 0.9rem;
  border-left: 1px dashed $mercury;
  padding: $spacer 0;

  @include media-breakpoint-down(md) {
    column-count: 2;
    column-gap: $spacer;
    flex: 0 0 100%;
    min-width: 100%;
    border: 0;
  }

  @include media-breakpoint-down(sm) {
    column-count: 1;
  }

  &__section {
    padding: 0 $spacer $spacer;
    display: flex;
    box-orient: vertical;
    box-direction: normal;
    flex-direction: column;
    min-width: 0;

    &__heading {
      font-size: 1.1rem;
      text-transform: uppercase;
    }

    ul:not(.list-unstyled) li {
      list-style: square;
    }
  }
}
